import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';

const NotFound = styled.div`
  padding-top: 6rem;
  padding-bottom: 6rem;
  text-align: center;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

const Headline = styled.h1`
  margin-bottom: 2rem;

  @media (max-width: ${props => props.theme.responsive.mobile}) {
    margin-bottom: 1rem;
  }
`;

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404 - Page Not Found</title>
      <meta name="description" content="Page not found" />
    </Helmet>

    <section className="section reveal">
      <NotFound className="container">
        <div className="columns is-centered">
          <div className="column is-two-thirds">
            <Headline>404: Page Not Found</Headline>
            <p>Perhaps return <Link to="/">home</Link> and see if you can find what you were looking for.</p>
          </div>
        </div>
      </NotFound>
    </section>
  </Layout>
);

export default NotFoundPage;
